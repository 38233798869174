import { environment } from '../../environments/environment';

export const authConfig = {
    loginUrl: `${environment.userServiceUrl}/oauth/authorize`,
    userinfoEndpoint: `${environment.apiUrl}/oauth/me?format=json`,
    clientId: environment.OAuthClientId,
    scope: 'openid profile',
    redirectUri: window.location.href,
    responseType: environment.OAuthResponseType
};
