import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { OAuthMe } from 'src/models/api/oauth-me';

import { AppComponent } from '../app.component';

import { OAuthService } from './oauth-service';

/**
 * Route guard checks oauth roles before rendering routes
 * @see https://ryanchenkie.com/angular-authentication-using-route-guards
 * @export
 */
@Injectable()
export class RoleGuardService implements CanActivate {
  constructor(
    private router: Router,
    private oauthService: OAuthService
  ) { }

  public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const expectedRole = route.data.expectedRole;
    let expectedOrgId = route.data.expectedOrgId;
    if (expectedOrgId === ':orgId') {
      expectedOrgId = Number(route.params['orgId']);
    }

    const me = await this.oauthService.loadUserProfile();
    const hasAccess = this.oauthService.hasPrivilege(me, expectedRole, expectedOrgId);
    if (!hasAccess) {
      this.router.navigate(['notAuthorized']);
      return false;
    }

    return true;
  }
}
