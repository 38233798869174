import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DayMonthYear } from 'src/models/day-month-year';
import { ApprovalStatus, ProofOfBirth } from 'src/models/proof-of-birth';
import { PageViewService } from 'src/services/page-view-service';
import { SessionService } from 'src/services/session-service';

import { AppComponent } from '../app.component';
import { BackButton } from '../back-button';
import { BaseComponent } from '../base/base.component';


@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss']
})

export class LegalComponent extends BaseComponent implements OnInit, BackButton {
  public get suffixes(): string[] {
    return ['Jr.', 'Sr.', 'I', 'II', 'III', 'IV'];
  }

  public get genders(): string[] {
    return ['Male', 'Female', 'Non-binary'];
  }

  constructor(private titleService: Title,
    public app: AppComponent,
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    public router: Router,
    public pageViewService: PageViewService) {
    super(router, app, pageViewService);

    this.titleService.setTitle('Proof of Birth | Legal Information');
    const dayMonthYear = new DayMonthYear();
    this.months = dayMonthYear.months;
    this.years = dayMonthYear.years.reverse();
    this.days = dayMonthYear.days;

    this.legalForm = this.createFormGroupWithBuilder(this.formBuilder);
  }

  public months: string[];
  public years: number[];
  public days: number[];

  private legalForm: UntypedFormGroup;
  private proofOfBirth: ProofOfBirth;
  private orgId: number;
  private personaId: number;
  private submitted = false;

  public get backButtonLabel(): string {
    return 'Upload a proof of birth';
  }

  public backButtonClick(): Promise<boolean> {
    return this.router.navigate(['../upload'], { relativeTo: this.activatedRoute });
  }

  // convenience getter for easy access to form fields
  public get f() { return this.legalForm.controls; }

  public get legalFormGetter(): UntypedFormGroup {
    return this.legalForm;
  }

  public async ngOnInit() {
    super.ngOnInit();
    if (this.app.organization && this.app.persona) {
      this.orgId = this.app.organization.id;
      this.personaId = this.app.persona.id;

      this.proofOfBirth = SessionService.getSessionProofOfBirthByPersonaId(this.personaId, this.orgId);

      if (this.proofOfBirth && this.proofOfBirth.legal) {
        this.legalForm = this.createFormGroupWithBuilderWithModel(this.formBuilder, this.proofOfBirth);
      }
    }
  }

  private createFormGroupWithBuilder(formBuilder: UntypedFormBuilder) {
    return formBuilder.group({
      first_name: [{ value: '', disabled: this.isReadonly }, Validators.required],
      middle_name: [{ value: '', disabled: this.isReadonly }],
      last_name: [{ value: '', disabled: this.isReadonly }, Validators.required],
      suffix: [{ value: '', disabled: this.isReadonly }],
      gender: [{ value: '', disabled: this.isReadonly }, Validators.required],
      day: [{ value: '', disabled: this.isReadonly }, Validators.required],
      month: [{ value: '', disabled: this.isReadonly }, Validators.required],
      year: [{ value: '', disabled: this.isReadonly }, Validators.required]
    });
  }

  private createFormGroupWithBuilderWithModel(formBuilder: UntypedFormBuilder, pobRecord: ProofOfBirth) {

    const options: Intl.DateTimeFormatOptions = { month: 'long' };
    const pobLegalDate = new Date(pobRecord.legal.date_of_birth);
    const legalMonth = new Intl.DateTimeFormat('en-US', options).format(pobLegalDate);
    return formBuilder.group({
      first_name: [{ value: pobRecord.legal.first_name, disabled: this.isReadonly }, Validators.required],
      middle_name: [{ value: pobRecord.legal.middle_name, disabled: this.isReadonly }],
      last_name: [{ value: pobRecord.legal.last_name, disabled: this.isReadonly }, Validators.required],
      suffix: [{ value: pobRecord.legal.suffix, disabled: this.isReadonly }],
      gender: [{ value: pobRecord.legal.gender, disabled: this.isReadonly }, Validators.required],
      day: [{ value: pobLegalDate.getDate(), disabled: this.isReadonly }, Validators.required],
      month: [{ value: legalMonth, disabled: this.isReadonly }, Validators.required],
      year: [{ value: pobLegalDate.getFullYear(), disabled: this.isReadonly }, Validators.required]
    });
  }

  private createDateFromInput(year: number, month: string, day: number) {
    const dateString = `${month} ${day}, ${year}`;
    return new Date(dateString).toISOString();
  }

  public async onSubmit() {
    this.submitted = true;
    if (this.legalForm.valid) {
      this.proofOfBirth.legal = {
        first_name: this.legalForm.controls.first_name.value,
        middle_name: this.legalForm.controls.middle_name.value,
        last_name: this.legalForm.controls.last_name.value,
        suffix: this.legalForm.controls.suffix.value,
        gender: this.legalForm.controls.gender.value,
        date_of_birth: this.createDateFromInput(this.legalForm.controls.year.value,
          this.legalForm.controls.month.value,
          this.legalForm.controls.day.value)
      };

      SessionService.setSessionProofOfBirth(this.proofOfBirth);
    } else {
      this.calculateErrors(this.legalForm);
    }

    if (this.legalForm.valid || this.isReadonly) {
      this.navigateToRoute('review');
    }
  }

  public get isReadonly() {
    return this.proofOfBirth && this.proofOfBirth.approval &&
      this.proofOfBirth.approval.status === ApprovalStatus.approved;
  }

  private calculateErrors(form: UntypedFormGroup | UntypedFormArray) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      const controlErrors: ValidationErrors = control.errors;
      if (controlErrors !== null) {
        Object.keys(controlErrors).forEach(keyError => {
          control.markAsTouched();
        });
      }
    });
  }
}
