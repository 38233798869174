import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Organization } from 'src/models/api/organization';
import { BossService } from 'src/services/boss-service';

@Injectable()
export class OrgResolver implements Resolve<Organization> {

  constructor(private router: Router, private bossService: BossService) {}

  public async resolve(route: ActivatedRouteSnapshot): Promise<Organization> {
    const orgId = Number(route.paramMap.get('orgId'));
    const org = await this.bossService.getOrganization(orgId);

  if (!org && orgId) {
      this.router.navigate(['login'], { skipLocationChange: true });
  }
    return org;
  }
}
