import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from './../../environments/environment';
import { authConfig } from './auth-config';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private router: Router) { }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        switch (err.status) {
            case 401:
                console.error('You must be logged in to use this tool.');
                this.router.navigate(['login'], { skipLocationChange: true });
                break;
            case 403:
                console.error('You do not have the appropriate privileges to use this tool. Please login and try again.');
                this.router.navigate(['notAuthorized'], { skipLocationChange: true });
                break;
            case 404:
                break;
            default:
                console.error(err.message);
                this.router.navigate(['error'], { skipLocationChange: true });
                break;
        }

        return of(err);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = this.interceptRequest(req);

        return next.handle(req).pipe(
            catchError(e => this.handleAuthError(e))
        );
    }

    private interceptRequest(req: HttpRequest<any>): HttpRequest<any> {
        const whitelist = [
            environment.userServiceUrl,
            environment.apiUrl
        ];

        const whitelisted = whitelist.find(d => req.url.startsWith(d));
        if (whitelisted) {
            switch (authConfig.responseType) {
                case 'none':
                    req = req.clone({
                        withCredentials: true,
                    });
                break;
            }
        }

        return req;
    }

    public startImplicitFlow() {
        const uri = this.getAuthUrl();
        window.location.href = uri;
    }

    private getAuthUrl() {
        let params = new HttpParams();
        params = params.set('client_id', authConfig.clientId);
        params = params.set('redirect_uri', authConfig.redirectUri);
        params = params.set('scope', authConfig.scope);
        params = params.set('response_type', authConfig.responseType);
        return authConfig.loginUrl + '?' + params.toString();
    }
}
