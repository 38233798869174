import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from 'src/models/api/api-response';
import { OAuthMe } from 'src/models/api/oauth-me';

import { authConfig } from './auth-config';
import { RoleAssignment } from './role-assignment';

@Injectable()
export class OAuthService {
    constructor(private http: HttpClient) { }

    public hasPrivilege(me: OAuthMe, expectedRole: string, expectedOrgId: number): boolean {
        let thirdNorth: RoleAssignment;
        let foundRole: RoleAssignment;
        try {
          const roles = me.user.role_assignments || [];
          thirdNorth = roles.find( r => r.role === 'third_north' );
          foundRole = roles.find( r => r.role === expectedRole && r.org_id === expectedOrgId);
          if (!foundRole && !thirdNorth) {
              return false;
          }
        } catch (e) {
          return false;
        }

        return true;
    }

    public async loadUserProfile(): Promise<OAuthMe> {
        return this.http.get<ApiResponse<OAuthMe>>(authConfig.userinfoEndpoint).toPromise()
          .then(response => response.result);
    }
}
