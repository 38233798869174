import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PageViewService } from 'src/services/page-view-service';

import { AppComponent } from '../app.component';
import { BackButton } from '../back-button';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-congrats',
  templateUrl: './congrats.component.html',
  styleUrls: ['./congrats.component.scss']
})
export class CongratsComponent extends BaseComponent implements OnInit, BackButton {
  private personaId: number;

  public constructor(
    public router: Router,
    public app: AppComponent,
    public pageViewService: PageViewService
  ) { super(router, app, pageViewService); }

  public ngOnInit() {
    super.ngOnInit();
    if (this.app.persona) {
      this.personaId = this.app.persona.id;
    }
  }

  public get backButtonLabel(): string {
    return 'Sign Out';
  }

  public backButtonClick(): Promise<boolean> {
    const returnUrl = environment.mySeUrl + '/user';
    window.location.href = environment.userServiceUrl
      + '/oauth/sign_out?redirect_uri=' + encodeURIComponent(returnUrl);
    return Promise.resolve(true);
  }

  public async onSubmit() {
    this.pageViewService.seEvent({
      ...this.app.pageDepths,
      action: 'POBUI.ViewProfileClick',
      event_type: 1
    });
    window.location.href = `${environment.mySeUrl}/user/profile/${this.personaId}`;
  }

}
