export const environment = {
  env: 'Staging',
  production: true,
  apiUrl: 'https://se-api.sestage.us',
  proofOfBirthServiceApiUrl: 'https://se-api.sestage.us/proof-of-birth',
  userServiceUrl: 'https://user.stage.ngin-staging.com',
  mySeUrl: 'https://app.stage.ngin-staging.com',
  OAuthClientId: '1d534b9c2f2749f830b9cef99e413d7f',
  OAuthResponseType: 'none',
};
