import { Injectable } from '@angular/core';
import { Overlay } from 'src/models/overlay';

@Injectable({
  providedIn: 'root'
})
export class OverlayService {

  constructor() { }

  private static currentState: Overlay;

  public enableOverlay(overlay: Overlay) {
    OverlayService.currentState = overlay;
  }

  public disableOverlay() {
    OverlayService.currentState = null;
  }

  public get currentState(): Overlay {
    return OverlayService.currentState;
  }
}
