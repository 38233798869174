import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { Persona } from 'src/models/api/persona';
import { UserService } from 'src/services/user-service';

@Injectable()
export class PersonaResolver implements Resolve<Persona> {

  constructor(private router: Router, private userService: UserService) {}

  public async resolve(route: ActivatedRouteSnapshot): Promise<Persona> {
    const personaId = Number(route.paramMap.get('personaId'));
    const persona = await this.userService.getPersona(personaId);
    if (!persona) {
      this.router.navigate(['notFound']);
    }
    return persona;
  }
}
