import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, ChildActivationEnd, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { OverlayService } from 'src/services/overlay-service';
import { PageViewService } from 'src/services/page-view-service';

import { Organization } from '../models/api/organization';
import { Persona } from '../models/api/persona';

import { OAuthService } from './auth/oauth-service';
import { BackButton } from './back-button';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public title = 'Proof Of Birth';
  public persona: Persona;
  public organization: Organization;
  public currentStep: number;
  public totalSteps: number;
  public loading = true;
  public showBackButton = false;
  public backButtonLabel: string;
  public backButtonClick: () => boolean;
  public pageDepths: any;
  public uploadError: boolean;

  private navEvents: Subscription;

  public constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private overlayService: OverlayService,
    private oAuthService: OAuthService,
    private pageViewService: PageViewService
  ) { }

  public ngOnInit() {
    this.currentStep = 0;
    this.totalSteps = 4;
    this.uploadError = false;

    this.navEvents = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.loading = true;
      }
      if (event instanceof NavigationEnd) {
        // TODO: Make this smarter...
        try {
          const orgRoute = this.route.snapshot.children[0];
          const personaRoute = orgRoute.children[0];
          const screenRoute = personaRoute.children[0];

          this.persona = personaRoute.data['persona'];
          this.organization = orgRoute.data['organization'];
          this.currentStep = screenRoute.data['currentStep'];
          this.setPageDepths(screenRoute);
        } catch (err) {
          console.log('Could not load context from routing...');
        }

        this.loading = false;
        this.overlayService.disableOverlay();
      }
    });
  }

  private setPageDepths(screenRoute: ActivatedRouteSnapshot) {
    this.pageDepths = {
      depth1: 'ProofOfBirth',
      depth2: screenRoute.url[0].path,
      boss_organization_id: this.organization.id
    };
  }

  public onChildActivate($event: BackButton) {
    if ($event) {
      this.backButtonClick = () => {
        $event.backButtonClick();
// tslint:disable-next-line: deprecation
        event.preventDefault();
        return false;
      };
      this.backButtonLabel = $event.backButtonLabel;
      this.cd.detectChanges();
    }
  }

  public setPersona(persona: Persona) {
    this.persona = persona;
  }

  public setOrganization(organization: Organization) {
    this.organization = organization;
  }

  public profileImageUrl(): string {
    if (!this.persona || !this.persona.profile_images) {
      return null;
    }

    const crop = this.persona.profile_images.find(i => i.image_type === 'crop');
    if (!crop) {
      return null;
    }

    return crop.url;
  }

  public async getLoggedInUserId(): Promise<number> {
    const me = await this.oAuthService.loadUserProfile();
    if (me && me.user) {
      return me.user.id;
    }
    return null;
  }

  public get overlay() {
    return this.overlayService.currentState;
  }
}
