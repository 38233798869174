import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SeFeAlertModule } from 'se-fe-alert';
import { SeFeAvatarModule } from 'se-fe-avatar';
import {
  seFeIconCheck,
  seFeIconCheckCircle,
  seFeIconChevronLeft,
  SeFeIconModule,
  seFeIconPencilFill,
  seFeIconUpload
} from 'se-fe-icon';
import { SeFeProgressBarModule } from 'se-fe-progress-bar';
import { SeFeSpinnerModule } from 'se-fe-spinner';
import { DisableControlDirective } from 'src/directives/disable-control';
import { environment } from 'src/environments/environment';

import { BossService } from '../services/boss-service';
import { PageViewService } from '../services/page-view-service';
import { ProofOfBirthService } from '../services/proof-of-birth-service';
import { UserService } from '../services/user-service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './auth/auth-interceptor';
import { OAuthService } from './auth/oauth-service';
import { RoleGuardService } from './auth/role-guard.service';
import { BaseComponent } from './base/base.component';
import { CongratsComponent } from './congrats/congrats.component';
import { LegalComponent } from './legal/legal.component';
import { ReviewComponent } from './review/review.component';
import { UploadComponent } from './upload/upload.component';




@NgModule({
  declarations: [
    AppComponent,
    UploadComponent,
    LegalComponent,
    ReviewComponent,
    CongratsComponent,
    BaseComponent,
    DisableControlDirective,
  ],
  imports: [
    SeFeIconModule.include([
      seFeIconCheck,
      seFeIconCheckCircle,
      seFeIconChevronLeft,
      seFeIconPencilFill,
      seFeIconUpload
    ]),
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    SeFeAlertModule,
    SeFeSpinnerModule,
    SeFeIconModule,
    SeFeAlertModule,
    SeFeAvatarModule,
    SeFeProgressBarModule
  ],
  providers: [
    ProofOfBirthService,
    UserService,
    BossService,
    RoleGuardService,
    OAuthService,
    AuthInterceptor,
    PageViewService,
    {
        provide: HTTP_INTERCEPTORS,
        useFactory: function(router: Router) {
          return new AuthInterceptor(router);
        },
        multi: true,
        deps: [Router]
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
