import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { OverlayService } from 'src/services/overlay-service';
import { PageViewService } from 'src/services/page-view-service';
import { SessionService } from 'src/services/session-service';

import { environment } from '../../environments/environment';
import { Approval, ApprovalStatus, ProofOfBirth, ProofOfBirthDocument, RejectionReason } from '../../models/proof-of-birth';
import { ProofOfBirthService } from '../../services/proof-of-birth-service';
import { AppComponent } from '../app.component';
import { BackButton } from '../back-button';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent extends BaseComponent implements OnInit, BackButton {
  private form: UntypedFormGroup = new UntypedFormGroup({
    upload: new UntypedFormControl(null, [
      Validators.required
    ])
  });

  @ViewChild('dropContainer') dropContainer: ElementRef;
  
  private selectedFile: File | null = null;
  private rejectionReasonDocument: any = null;
  
  public acceptableDocumentsDisplay: string
  public editDocument = true;
  public proofOfBirth: ProofOfBirth;

  public constructor(
    public app: AppComponent,
    public router: Router,
    private pobService: ProofOfBirthService,
    private overlayService: OverlayService,
    public pageViewService: PageViewService
  ) {
    super(router, app, pageViewService);
  }

  private displayErrorOverlay(errorTitle: string): void {
    console.error(errorTitle);
    this.overlayService.disableOverlay();
    this.overlayService.enableOverlay({title: errorTitle});
    setTimeout(() => { this.overlayService.disableOverlay(); }, 6000);
  }

  public get backButtonLabel(): string {
    return 'Sign Out';
  }

  public get formGetter(): UntypedFormGroup {
    return this.form;
  }

  public backButtonClick(): Promise<boolean> {
    const returnUrl = environment.mySeUrl + '/user';
    window.location.href = environment.userServiceUrl
      + '/oauth/sign_out?redirect_uri=' + encodeURIComponent(returnUrl);
    return Promise.resolve(true);
  }

  public async ngOnInit() {
    super.ngOnInit();
    if (this.app.organization && this.app.persona) {
      if (this.app.uploadError) {
        this.pageViewService.seEvent({
          ...this.app.pageDepths,
          action: 'POBUI.UploadError',
          event_type: 5
        });
      }

      this.setAcceptableDocumentsDisplay()
      this.proofOfBirth = SessionService.getSessionProofOfBirthByPersonaId(this.app.persona.id, this.app.organization.id);

      if (!this.proofOfBirth) {
        this.proofOfBirth = await this.pobService.getProofOfBirthByPersonaId(this.app.persona.id, this.app.organization.id);
      }

      if (this.proofOfBirth) {

        if (this.proofOfBirth.document
          && this.proofOfBirth.document.s3_key
          && this.proofOfBirth.legal
          && !this.proofOfBirth.approval) {
          this.editDocument = false;
        }

        if (this.proofOfBirth.approval) {
          this.rejectionReasonDocument = this.createRejectionReasonDocument(this.proofOfBirth.approval);

          if (this.proofOfBirth.approval.status === ApprovalStatus.approved) {
            // If POB is approved forward to congrats screen
            this.navigateToRoute('congrats');
          } else if (this.proofOfBirth.approval.status === ApprovalStatus.resubmitted
            || (this.proofOfBirth.approval.status === ApprovalStatus.pending
              && this.proofOfBirth?.document?.s3_key)) {
            // when status is resubmitted show uploaded state
            this.editDocument = false;
          }
        }
      }
    }
  }

  public onFileChange($event): void {
    const [file] = $event.target.files;
    this.selectedFile = file as File;
    this.editDocument = false;
    this.pageViewService.seEvent({
      ...this.app.pageDepths,
      action: 'POBUI.AddImage',
      event_type: 5
    });
  }

  public handleDropFile(e) {
    e.preventDefault();
    this.selectedFile = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    this.editDocument = false;
  }

  public dragOver(e) {
    e.preventDefault();
    // this.dropContainer.nativeElement.classList.add('drop-container-over');
  }

  public dragLeave(e) {
    e.preventDefault();
    // this.dropContainer.nativeElement.classList.remove('drop-container-over');
  }

  public get fileName(): string {
    const fileName = this.selectedFile
      ? this.selectedFile.name
      : this.proofOfBirth.document.name;
    return fileName;
  }

  public showForm() {
    this.editDocument = true;
  }

  public nextRoute() {
    if (this.skipToReview()) {
      this.navigateToRoute('review');
    } else {
      // Normal Flow to legal information
      this.navigateToRoute('legal');
    }
  }

  public skipToReview(): boolean {
    let skip = false;
    // users are re-uploading from a rejection
    if (this.proofOfBirth && this.proofOfBirth.approval != null
      && this.proofOfBirth.approval.status === ApprovalStatus.rejected
      && this.proofOfBirth.approval.rejection_reason !== null) {
      skip = true;
    }

    // users are editing before submitting pob or before approval
    // Conditions:
    // dont have an Approval
    // have legal info
    // have a POB record
    if (this.proofOfBirth && !this.proofOfBirth.approval && this.proofOfBirth.legal != null) {
      skip = true;
    }
    return skip;
  }

  public async onSubmit() {
    this.app.uploadError = false;
    let skipLegalInfo: boolean = this.skipToReview();

    if (!this.proofOfBirth) {
      this.proofOfBirth = await this.pobService.putProofOfBirth({
        id: `${this.app.persona.id}-${this.app.organization.id}`,
        persona_id: this.app.persona.id,
        org_id: this.app.organization.id
      } as ProofOfBirth);
      skipLegalInfo = false;
    }

    if (this.proofOfBirth.document && this.proofOfBirth.document.s3_key && this.proofOfBirth.legal) {
      // this is a re-upload we have legal info so skip to review
      skipLegalInfo = true;
    }

    if (this.selectedFile) {
      // Check the selected file size
      if (!this.selectedFile.size || this.selectedFile.size <= 0) {
        this.pageViewService.seEvent({
          ...this.app.pageDepths,
          action: 'POBUI.SelectedFileError',
          event_type: 5
        });
        this.displayErrorOverlay('Error uploading file. Empty file.');
        return;
      }

      // Upload the file
      this.overlayService.enableOverlay({
        title: 'Uploading file'
      });

      const presignResponse = await this.pobService.getPresignUrl(this.proofOfBirth.id, this.selectedFile.type);
      const fileUpload = presignResponse && presignResponse.url ?
        await this.pobService.uploadToPresignUrl(presignResponse.url, this.selectedFile) : null;

      // Check the fileUpload response
      if (!presignResponse || !fileUpload) {
        this.displayErrorOverlay('Error uploading file, please try again.');
        this.pageViewService.seEvent({
          ...this.app.pageDepths,
          action: 'POBUI.S3UploadError',
          event_type: 5
        });
        return;
      }
      this.pageViewService.seEvent({
        ...this.app.pageDepths,
        action: 'POBUI.SubmitImage',
        event_type: 5
      });
      // Update uploaded document
      this.proofOfBirth.document = {
        content_type: presignResponse.contentType,
        name: this.selectedFile.name,
        s3_key: presignResponse.key,
        size: this.selectedFile.size,
        user_id: await this.app.getLoggedInUserId(),
        date: new Date(),
      };
    }

    SessionService.setSessionProofOfBirth(this.proofOfBirth);

    if (skipLegalInfo) {
      this.navigateToRoute('review');
    } else {
      // Normal Flow to legal information
      this.navigateToRoute('legal');
    }
  }

  public get rejectionReasonDocumentGetter(): any {
    return this.rejectionReasonDocument;
  }

  public get uploadError(): boolean {
    return this.app.uploadError;
  }

  private setAcceptableDocumentsDisplay(): void {
    const acceptableDocuments = ['birth certificate', 'passport']
    if (this.app.organization.id !== 176170) {
      acceptableDocuments.push('government issued ID', `driver's license`)
    }
    acceptableDocuments.splice(acceptableDocuments.length - 1, 0, `or ${acceptableDocuments.pop()}.`)
    this.acceptableDocumentsDisplay = acceptableDocuments.join(', ')
  }

  private createRejectionReasonDocument(approval: Approval): any {
    if (!approval || approval.status !== ApprovalStatus.rejected || !approval.rejection_reason) {
      return null;
    }
    switch (approval.rejection_reason) {
      case RejectionReason.image_is_cropped:
        return {
          title: 'Image is cropped',
          // tslint:disable-next-line: max-line-length
          text: 'The document that was uploaded is cropped, and we cannot verify if the information is correct. Please try again and resubmit.'
        };
      case RejectionReason.image_is_unreadable:
        return {
          title: 'Image is unreadable',
          // tslint:disable-next-line: max-line-length
          text: 'The document that was uploaded is unreadable, and we cannot verify if the information is correct. Please try again and resubmit.'
        };
      case RejectionReason.other:
        return {
          title: 'Other reason specified',
          text: approval.other_details
        };
      case RejectionReason.wrong_child:
        return {
          title: 'Documentation is for the wrong child',
          text: `The information on the document submitted does not match the child's profile.`
        };
      case RejectionReason.wrong_documentation:
        return {
          title: 'Wrong documentation',
          // tslint:disable-next-line: max-line-length
          text: `The image that was uploaded is not a valid proof-of-birth. Please upload a ${this.acceptableDocumentsDisplay}`
        };
      default:
        return null;
    }
  }
}
