import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Approval, ApprovalStatus, LegalInformation, ProofOfBirth, ProofOfBirthDocument, RejectionReason } from 'src/models/proof-of-birth';
import { OverlayService } from 'src/services/overlay-service';
import { PageViewService } from 'src/services/page-view-service';
import { ProofOfBirthService } from 'src/services/proof-of-birth-service';
import { SessionService } from 'src/services/session-service';

import { AppComponent } from '../app.component';
import { BackButton } from '../back-button';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent extends BaseComponent implements OnInit, BackButton {
  public proofOfBirth: ProofOfBirth;
  private orgId: number;
  private personaId: number;
  public submitting: boolean = false;

  public constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public app: AppComponent,
    private pobService: ProofOfBirthService,
    private overlayService: OverlayService,
    public pageViewService: PageViewService
  ) { super(router, app, pageViewService); }

  public get dateOfBirthString(): string {
    const date_of_birth = this.proofOfBirth.legal.date_of_birth;
    if (date_of_birth) {
      const date = new Date(this.proofOfBirth.legal.date_of_birth);
      const month = date.toLocaleString('en-us', { month: 'long' });
      const day = date.getDate();
      const year = date.getFullYear();
      return `${month}, ${day} ${year}`;
    }
    return '';
  }

  public get backButtonLabel(): string {
    return 'Legal information';
  }

  public backButtonClick(): Promise<boolean> {
    return this.router.navigate(['../legal'], { relativeTo: this.activatedRoute });
  }

  public ngOnInit() {
    super.ngOnInit();
    // HACK: initialize this object so the tests pass
    this.proofOfBirth = {
      legal: {
        first_name: null,
        middle_name: null,
        last_name: null,
        suffix: null,
        gender: null,
        date_of_birth: null
      } as LegalInformation,
      document: {
        name: null
      } as ProofOfBirthDocument
    } as ProofOfBirth;

    if (this.app.organization && this.app.persona) {
      this.orgId = this.app.organization.id;
      this.personaId = this.app.persona.id;
      this.proofOfBirth = SessionService.getSessionProofOfBirthByPersonaId(this.personaId, this.orgId);
      if (!this.proofOfBirth?.document?.s3_key) {
        this.app.uploadError = true;
        this.navigateToRoute('upload');
      }
    }
  }

  public updateApprovalStatusForSubmission() {
    // Initial submission, set approval with initial status of pending
    if (!this.proofOfBirth.approval || !this.proofOfBirth.approval.status) {
      this.proofOfBirth.approval = {
        date: new Date(),
        status: ApprovalStatus.pending,
      } as Approval;
    } else if (this.proofOfBirth.approval
      && this.proofOfBirth.document
      && this.proofOfBirth.approval.rejection_reason
      && this.proofOfBirth.approval.status === ApprovalStatus.rejected) {
      // Correcting rejected document, update status to resubmitted,
      // remove rejection reason, and update document and approval date to now
      if (this.proofOfBirth.approval.rejection_reason === RejectionReason.other) {
        this.proofOfBirth.approval.other_details = null;
      }
      this.proofOfBirth.approval.status = ApprovalStatus.resubmitted;
      this.proofOfBirth.approval.rejection_reason = null;
      this.proofOfBirth.approval.date = new Date();
      this.proofOfBirth.document.date = new Date();
      this.proofOfBirth.approval.user_id = null;
      this.proofOfBirth.approval.persona_id = null;
      this.proofOfBirth.approval.affiliated_org_id = null;
    } else if (this.proofOfBirth.approval && this.proofOfBirth.approval.status === ApprovalStatus.resubmitted) {
      this.proofOfBirth.approval.date = new Date();
    }
  }

  public async onSubmit() {
    this.submitting = true
    this.overlayService.enableOverlay({
      title: 'Submitting'
    });
    this.updateApprovalStatusForSubmission();
    const result = await this.pobService.putProofOfBirth(this.proofOfBirth);
    SessionService.setSessionProofOfBirth(result);
    this.navigateToRoute('congrats');
  }

  public get isReadonly() {
    return this.proofOfBirth.approval && this.proofOfBirth.approval.status === ApprovalStatus.approved;
  }

}
