import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Organization } from 'src/models/api/organization';

import { ApiResponse } from '../models/api/api-response';

@Injectable()
export class BossService {

  private baseUrl: string;
  private baseOptions: object;

  public constructor(
    private http: HttpClient) {
      this.baseUrl = environment.apiUrl;
      this.baseOptions = {
        withCredentials: true
      };
  }

  public async getOrganization(id: number): Promise<Organization> {
    const response = await this.http.get<ApiResponse<Organization>>(
      this.baseUrl + `/organization_public/${id}`,
      this.baseOptions
    ).toPromise();
    if (!response) {
      return null;
    }
    return response.result;
  }
}
