import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PageViewService } from 'src/services/page-view-service';

import { AppComponent } from '../app.component';

@Component({
  selector: 'app-base',
  template: `NO UI TO BE FOUND HERE!`,
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {

  constructor(
    public router: Router,
    public app: AppComponent,
    public pageViewService: PageViewService
  ) { }

  ngOnInit() {
    this.pageViewService.pageView(this.app.pageDepths);
  }

  public navigateToRoute(route: string) {
    if ( this.app.organization && this.app.persona ) {
      const org_id = this.app.organization.id;
      const persona_id = this.app.persona.id;

      this.router.navigateByUrl(`/org/${org_id}/persona/${persona_id}/${route}`);
    }
  }

}
