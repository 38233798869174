import { Injectable } from '@angular/core';
import { SeFeAnalytics } from 'se-analytics-js';

import { environment } from '../environments/environment';
import { EventAttributes, PageAttributes } from '../models/analytics.attributes';

@Injectable({
  providedIn: 'root'
})
export class PageViewService {
  private analytics: SeFeAnalytics = new SeFeAnalytics(
    { platProp: 'membership_ui', platformSubSec: 'Memberships', currentEnv: environment.env }
  );

  constructor() {
    // noop
  }

  public pageView(pageAttributes: PageAttributes): void {
    try {
      const data = { ...pageAttributes };
      this.analytics.push({ type: 'pageView', data });
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.error('se-analytics pageView Error', e);
    }
  }

  public seEvent(
    eventAttributes: EventAttributes
  ): void {
    try {
      const data = { ...eventAttributes };
      this.analytics.push({ type: 'seEvent', data });
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.error('mParticle seEvent Error', e);
    }
  }
}
