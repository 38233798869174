export class DayMonthYear {

  public years: number[];
  public months: string[];
  public days: number[];

  public constructor() {
    const date = new Date();
    const currentYear = date.getFullYear();
    // set values for year dropdown
    this.years = [];
    for (let i = currentYear - 100; i <= currentYear; i++) {
      this.years.push(i);
    }

    this.months = ['January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'];

    this.days = [];
    for (let i = 1; i <= 31; i++) {
      this.days.push(i);
    }
  }
}
