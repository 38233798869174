import { ProofOfBirth } from 'src/models/proof-of-birth';

export class SessionService {

    public static getSessionProofOfBirth(id: string): ProofOfBirth {
        try {
            const sessionProofOfBirth = sessionStorage.getItem(this.getKey(id));
            if (sessionProofOfBirth) {
                let proofOfBirth: ProofOfBirth;
                proofOfBirth = JSON.parse(sessionProofOfBirth) as ProofOfBirth;
                return proofOfBirth;
            }
        } catch (err) {
            return null;
        }
    }

    public static getSessionProofOfBirthByPersonaId(personaId: number, orgId: number): ProofOfBirth {
        return this.getSessionProofOfBirth(`${personaId}-${orgId}`);
    }

    public static setSessionProofOfBirth(proofOfBirth: ProofOfBirth) {
        sessionStorage.setItem(this.getKey(proofOfBirth.id), JSON.stringify(proofOfBirth));
    }

    private static getKey(id: string): string {
        return `pob-${id}`;
    }
}
