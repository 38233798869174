export interface ProofOfBirth {
  id: string;
  persona_id: number;
  org_id: number;
  legal: LegalInformation;
  approval: Approval;
  document: ProofOfBirthDocument;
}

export interface LegalInformation {
  first_name: string;
  middle_name: string;
  last_name: string;
  suffix: string;
  gender: string;
  date_of_birth: string;
}

export interface ProofOfBirthDocument {
  content_type: string;
  name: string;
  s3_key: string;
  size: number;
  user_id: number;
  date?: Date;
  expiration_date?: Date;
}

export interface Approval {
  date: Date;
  rejection_reason: RejectionReason;
  other_details?: string;
  status: ApprovalStatus;
  user_id: number;
  persona_id?: number;
  affiliated_org_id?: number;
}

export enum RejectionReason {
  image_is_cropped = 'image_is_cropped',
  image_is_unreadable = 'image_is_unreadable',
  other = 'other',
  wrong_child = 'wrong_child',
  wrong_documentation = 'wrong_documentation',
}

export enum ApprovalStatus {
  approved = 'approved',
  pending = 'pending',
  rejected = 'rejected',
  resubmitted = 'resubmitted'
}
