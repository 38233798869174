import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../environments/environment';
import { PresignResponse } from '../models/presign-response';
import { ProofOfBirth } from '../models/proof-of-birth';
@Injectable()
export class ProofOfBirthService {
  private baseUrl: string;
  private baseOptions: object;

  public constructor(
    private http: HttpClient) {
      this.baseUrl = environment.proofOfBirthServiceApiUrl;
      this.baseOptions = {
        withCredentials: true,
      };
  }

  public async getProofOfBirth(id: string): Promise<ProofOfBirth> {
    return await this.http.get<ProofOfBirth>(
      this.baseUrl + `/record/${id}`,
      this.baseOptions
    ).toPromise();
  }

  public async getProofOfBirthByPersonaId(persona_id: number, org_id: number): Promise<ProofOfBirth> {
    return await this.getProofOfBirth(`${persona_id}-${org_id}`);
  }

  public async putProofOfBirth(proofOfBirth: ProofOfBirth): Promise<ProofOfBirth> {
    return await this.http.put<ProofOfBirth>(
      this.baseUrl + `/record/${proofOfBirth.id}`, proofOfBirth,
      this.baseOptions
    ).toPromise();
  }

  public async getPresignUrl(id: string, type: string): Promise<PresignResponse> {
    return await this.http.get<PresignResponse>(
      this.baseUrl + `/presign/${id}?type=${type}`,
      this.baseOptions
    ).toPromise();
  }

  public async uploadToPresignUrl(presignUrl: string, file: File): Promise<boolean> {
    const promise = new Promise<boolean>((resolve) => {
    const headers: HttpHeaders = new HttpHeaders({'Content-Type': file.type});
    this.http.put(presignUrl, file, { headers, observe: 'response', responseType: 'text' })
      .subscribe(
        resp => {
          resolve(resp.ok); },
        error =>  {
          resolve(false); }
      );
    });
    return promise;
  }
}
