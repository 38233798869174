import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrgResolver } from 'src/resolvers/org-resolver';
import { PersonaResolver } from 'src/resolvers/persona-resolver';

import { RoleGuardService } from './auth/role-guard.service';
import { CongratsComponent } from './congrats/congrats.component';
import { LegalComponent } from './legal/legal.component';
import { ReviewComponent } from './review/review.component';
import { UploadComponent } from './upload/upload.component';

const routes: Routes = [
  {
    path: 'org/:orgId',
    resolve: {
      organization: OrgResolver
    },
    children: [
      {
        path: 'persona/:personaId',
        resolve: {
          persona: PersonaResolver
        },
        children: [
          {
            path: 'upload',
            component: UploadComponent,
            data: {
              currentStep: 1
            }
          },
          {
            path: 'legal',
            component: LegalComponent,
            data: {
              currentStep: 2
            }
          },
          {
            path: 'review',
            component: ReviewComponent,
            data: {
              currentStep: 3
            }
          },
          {
            path: 'congrats',
            component: CongratsComponent,
            data: {
              currentStep: 4
            }
          }
        ]
      }
    ]
  },
  // Authentication Routes
  {
    path: 'login',
    loadChildren: () => import('../login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'notAuthorized',
    loadChildren: () => import('../notAuthorized/not-authorized.module').then(m => m.NotAuthorizedModule)
  },
  {
    path: 'notFound',
    loadChildren: () => import('../notFound/not-found.module').then(m => m.NotFoundModule)
  },
  {
    path: 'error',
    loadChildren: () => import('../error/error.module').then(m => m.ErrorModule)
  },

  // Default Route
  { path: '**', redirectTo: 'notFound', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  providers: [
    OrgResolver,
    PersonaResolver
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
